const program = JSON.parse(localStorage.getItem('program')) || [];
// console.log(program)
let data = [];
program.map((pr)=>{
    // console.log(pr.date);return;
    let prog = 
        {
            "TaskID": pr._id,
            "OwnerID": 2,
            "Title": pr.name,
            "Info": pr.title,
            "Support":pr.support,
            "Description": "",
            "StartTimezone": null,
            "Start": new Date(`${pr.date} ${pr.startTime}`),
            "End": new Date(`${pr.date} ${pr.endTime}`),
            // "StartTime": pr.startTime,
            // "EndTime": pr.endTime,
            "EndTimezone": null,
            "RecurrenceRule": null,
            "RecurrenceID": null,
            "RecurrenceException": null,
            "Color" : 'green',
            "Size" : '25'
        }
        // console.log(prog.End)

    data.push(prog)
})
const baseData = data;

export const customModelFields = {
    id: 'TaskID',
    title: 'Title',
    description: 'Description',
    start: 'Start',
    end: 'End',
    recurrenceRule: 'RecurrenceRule',
    recurrenceId: 'RecurrenceID',
    recurrenceExceptions: 'RecurrenceException'
};

const currentYear = new Date().getFullYear();
const parseAdjust = (eventDate) => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
};

const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const displayDate = new Date();

export const sampleData = baseData.map(dataItem => (
    {
        id: dataItem.TaskID,
        start: parseAdjust(dataItem.Start),
        end: parseAdjust(dataItem.End),
        startTimezone: dataItem.StartTimezone,
        endTimezone: dataItem.EndTimezone,
        title: dataItem.Title,
        description: dataItem.Description,
        color: dataItem.Color,
        size: dataItem.Size,
        info: dataItem.Info,
        support: dataItem.Support,
    }
));

