import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Switch, Route, Redirect } from "react-router-dom";
import ExportScheduler from './Components/ExportScheduler';
import ProgramContext from './Components/ProgramContext';
import { sampleData } from '../src/Components/events-utc';
import Login from './Components/Login';
// import {getPrograms} from './Api/Programs';
import { Home } from './Components/Home';
import axios from 'axios';
import { connect } from 'react-redux';
import { getPrograms } from './features/dataSlice';

class App extends Component<any, any>{
  private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
  constructor(props : any){
    super(props);
    this.state = {
      isLoggedIn: true,
      selectedSupport : '',
      sliderValue: localStorage.getItem("sliderValue"),
      programs: localStorage.getItem('program') || [],
      programData: sampleData
    }
  }
  componentDidMount(): void {
    
  }
   selectSupport = async (event)=>{
     localStorage.setItem('selectedSupport', event.target.value);
     this.setState({selectedSupport: event.target.value})
     console.log('hh')
     this.props.getPrograms()
    //  getPrograms(event.target.value)
    // console.log(this.baseUrl)
  //   await axios.get(`${this.baseUrl}/program/support/${event.target.value}`)
  //   .then((response: any) => {
  //     if(response.data.error){
  //     }else{   
  //       const programs = response.data.data.slice(0,50);
  //       console.log(programs)
  //       localStorage.setItem('program', JSON.stringify(programs));
  //     }
  // }).catch((err) => console.log(err.message));

  this.setState({programs: JSON.parse(localStorage.getItem('programs'))})
  }
  logout = ()=>{
    localStorage.removeItem('userData');
    this.setState({
      isLoggedIn:false
    })
  }
  handleSlider = (e)=>{
    const newVal = parseInt(e.target.value);
    this.setState({sliderValue: newVal});
    console.count()
    // localStorage.setItem("sliderValue", e.target.value)
  }
  render(){
    // console.log(JSON.parse(localStorage.getItem('support')))
    const contextValue = {
      selectSupport:this.selectSupport,
      handleSlider:this.handleSlider,
      logout: this.logout,
      sliderValue: this.state.sliderValue,
      programs: JSON.parse(localStorage.getItem('program')),
      supports: JSON.parse(localStorage.getItem('support')),
      programData: this.state.programData,
      selectedSupport: localStorage.getItem('selectedSupport') || null
    }

      return (
        <ProgramContext.Provider value={contextValue}>
        <div className="App">
          <Switch>
            <PrivateRoute exact path="/" component={Home}  />
            <PrivateRoute exact path="/export" component={ExportScheduler} />
            <Route exact path="/login" component={Login} />
          </Switch>
        </div>
        </ProgramContext.Provider>
      );
  }
}
const PrivateRoute = ({ component: Component, ...rest }) => 
(  
  <Route {...rest} render={props => 
  (
    localStorage.getItem('userData') ? <Component {...props} /> : <Redirect to={{pathname: '/login'}}/>
  )}/>
);

function mapDispatchToProps(dispatch) {
  return { getPrograms: () => dispatch(getPrograms()) };
 }

export default connect(null, mapDispatchToProps)(App)
