import { configureStore } from "@reduxjs/toolkit"
import dataSlice from "../features/dataSlice"
import slideReducer from "../features/slideSlice"

export const store = configureStore({
    reducer: {
        slide : slideReducer,
        data: dataSlice
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AddDispatch = typeof store.dispatch