import * as React from 'react';
import moment from "moment";
export default class Programdata extends React.Component<any, any> {

getProgram = ()=>{
    // console.log(this.props);
    // return;
        let data = [];
        const categories = JSON.parse(localStorage.getItem('category')) || [];

        this.props.map((pr:any)=>  {
            let color = categories.filter((category)=>{
                return pr.category === category.name;
            }).map((category)=>{return category.color})
            let prog = 
                {
                    "TaskID": pr._id,
                    "OwnerID": 2,
                    "Title": pr.name,
                    "Info": pr.title,
                    "Support":pr.support,
                    "Description": "",
                    "StartTimezone": null,
                    "Start": moment(`${pr.date} ${pr.startTime}`, 'YYYY-MM-DD HH:mm:ss').format(),
                    "End": moment(`${pr.date} ${pr.endTime}`, 'YYYY-MM-DD HH:mm:ss').format(),
                    // "StartTime": pr.startTime,
                    // "EndTime": pr.endTime,
                    "EndTimezone": null,
                    "RecurrenceRule": null,
                    "RecurrenceID": null,
                    "RecurrenceException": null,
                    "Color" : color,
                    "Size" : '25'
                }
            data.push(prog)
        })
        return this.sampleData(data);
    }

    sampleData = (data)=>{
        const parseAdjust = (eventDate) => {
            const date = new Date(eventDate);
            return date;
        };
        return data.map(dataItem => (
            {
                id: dataItem.TaskID,
                start: parseAdjust(dataItem.Start),
                end: parseAdjust(dataItem.End),
                startTimezone: dataItem.StartTimezone,
                endTimezone: dataItem.EndTimezone,
                title: dataItem.Title,
                description: dataItem.Description,
                color: dataItem.Color,
                size: dataItem.Size,
                info: dataItem.Info,
                support: dataItem.Support,
            }
        ));
    }

}