import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Scheduler, SchedulerItem, SchedulerItemContent, SchedulerSlot, SchedulerSlotProps, WeekView, WorkWeekView} from '@progress/kendo-react-scheduler';
import { ZonedDate, getDate, addDays, MS_PER_HOUR } from '@progress/kendo-date-math';
import { filteredPrograms} from '../Api/Programs';
import { displayDate } from './events-utc';
import { CustomSlot } from "./CustomSlot";
import { CustomHeader } from "./header";
import {  startOfWeek } from 'date-fns'
import '../css/scheduler.css';
import days from './days.json';
import ProgramContext from './ProgramContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import { IntlProvider, LocalizationProvider, load } from '@progress/kendo-react-intl';
import { getPrograms } from '../features/dataSlice';
import Programdata from './Programdata';
// import Programdata from './Programdata';
load(
  likelySubtags,
  currencyData,
  weekData,
  frLocalCurrency,
  frNumbers,
  frCaGregorian,
  frDateFields
);
export const Home = () => {
  const dispatch = useDispatch();
  const { data, loading } =useSelector((state : RootState) => state.data)
   console.log(loading)
  const localProgram = new Programdata(data);
  const programs = JSON.parse(localStorage.getItem('programs')) ? localProgram.getProgram() : [];

React.useEffect(()=>{
  dispatch(getPrograms());
},[])
  const programContext = React.useContext(ProgramContext)
  const duration = useSelector((state: RootState ) => state.slide.slideValue)
  const customDateRange = ({ date, intl, timezone, numberOfDays }) => {
    date = new Date(date);
     const selectedSupport = localStorage.getItem('selectedSupport');
     const supports = JSON.parse(localStorage.getItem('support')) || [];
     const startDay = supports.filter((support)=>{
         return support.name === selectedSupport
     }).map((support)=>{return support.startDay})
    const startWeekDate = startOfWeek(date, {weekStartsOn: days[startDay]});
    const normalized = ZonedDate.fromLocalDate(startWeekDate, timezone);
    // Add an offset of 5 hours
    const firstDay = new Date(getDate(normalized).getTime() + 6 * MS_PER_HOUR);
    const lastDay = addDays(firstDay, numberOfDays);
    const zonedStart = ZonedDate.fromUTCDate(toUTCDateTime(firstDay), timezone);
    const zonedEnd = ZonedDate.fromUTCDate(toUTCDateTime(lastDay), timezone);
    const start = new Date(zonedStart.getTime());
    const end = new Date(zonedEnd.getTime());
    return {
      start,
      end,
      zonedStart,
      zonedEnd
    };
  };

    return (
      <LocalizationProvider language='fr-FR'>
        <IntlProvider locale='fr'>

      <Scheduler 
        header={(props) =>
          <CustomHeader {...props} />
        }
        data={programs} defaultDate={displayDate}
        footer={(props) =>
          <React.Fragment />
        }
        item={CustomItem}
        >
        <WeekView 
        dateRange={customDateRange} 
        slotDuration={duration}
        numberOfDays={7} 
        slotDivisions={1}
        viewItem={CustomSlot}
        />
      </Scheduler>
        </IntlProvider>
    </LocalizationProvider>
    );
};

function toUTCDateTime(localDate): Date {
  return new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
      localDate.getMilliseconds()
    )
  );
}
const CustomItem = props => {
  return (
    <SchedulerItem {...props}
      style={{
        ...props.style,
        background: `${props.dataItem.color}`,
        fontSize: `${props.dataItem.size}`,
        width: '100%'
      }}
    >
    </SchedulerItem>
  );
};
