import React from "react";
export default React.createContext({
    selectSupport : (event :any) => {},
    handleSlider : (event :any) => {},
    logout : (event :any) => {},
    sliderValue:0,
    programs: [],
    supports:[],
    programData:[],
    selectedSupport: '',

});
