import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    slideValue: localStorage.getItem('slideValue') ? parseInt(localStorage.getItem('slideValue')) :  15
}
export const slideSlice = createSlice({
    name: 'slide',
    initialState,
    reducers: {
        addSlide : (state, action: PayloadAction<number>) => {
            state.slideValue = action.payload
        }
          
    }
})

export const { addSlide } = slideSlice.actions
export default slideSlice.reducer;