import React, { Component } from "react";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import "../css/login.css"
import axios from "axios";
import { Redirect } from "react-router-dom";
const CustomInput = ({ fieldType, ...others }) => {
  return (
    <div>
      <Input
        type={fieldType}
        {...others} />
      <ValidationMessage {...others} />
    </div>
  );
};



const ValidationMessage = ({ valid, visited, validationMessage }:any) => {
  return (
    <>
    { !valid && visited &&
        (<div className="required">{validationMessage}</div>)}
    </>
  );
}

const emailValidator = (value) => (
  new RegExp(/\S+@\S+\.\S+/).test(value) ? "" : "Please enter a valid email."
);
const loginValidator = (value : string) => (
  value ? "" : "Please enter a valid login."
);
const requiredValidator = (value) => {
  return value ? "" : "This field is required";
}
interface Props {}
interface State {
    isLoggedIn: boolean,
}
export default class Login extends Component<Props, State> {
  private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

  constructor(props:any){
    super(props)
    this.state = {
        isLoggedIn: localStorage.getItem('userData') ? true : false,
    }
}
  handleSubmit = (data,event) => {
    event.preventDefault();
    axios.post(`${this.baseUrl}/auth/login`,data)
    .then((response: any) => {
      if(response.data.error){
          console.log(response)
      }else{  
        localStorage.setItem('userData',JSON.stringify(response.data));
        this.setState({
          isLoggedIn:true
        })
      }
      
    }).catch((err) => console.log(err.message));  
  }
  
  render(){
    if (this.state.isLoggedIn){
      return (<Redirect to={'/'}/>)
    }
    return (
      <div className="header">
        <Form
        onSubmit={this.handleSubmit}
        render={(formRenderProps) => (
            <form onSubmit={formRenderProps.onSubmit}>
            <h1>logo</h1>
            <h1>Se connecter</h1>

            <Field
                label="Login"
                name="login"
                fieldType="text"
                component={CustomInput}
                validator={[requiredValidator, loginValidator]} />
            
            <Field
                label="Mot de passe"
                name="password"
                fieldType="password"
                component={CustomInput}
                validator={requiredValidator} />
            <button className="loginButton" disabled={!formRenderProps.allowSubmit}>
              Se connecter
            </button>
            </form>
        )}>
        </Form>
    </div>

  );
  }

}
