import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getPrograms =  createAsyncThunk("program/support/",async() => {
    const support = localStorage.getItem('selectedSupport');
    return fetch(`http://docker-swarm.adwanted.group:5010/program/support/${support}`).then((res) => res.json());
});
const initialState = {
    data: localStorage.getItem('programs') ? JSON.parse(localStorage.getItem('programs')) : [],
    loading: false
}
export const dataSlice = createSlice({
    
    name: 'data',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getPrograms.pending, (state, action) => {
          state.loading = true;
        })
        builder.addCase(getPrograms.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data
            localStorage.setItem('programs', JSON.stringify(action.payload.data))
        })
        builder.addCase(getPrograms.rejected, (state, action) => {
            state.loading = false;
        })
      },
})

export default dataSlice.reducer;