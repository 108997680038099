import * as React from 'react';
import { SchedulerHeader, SchedulerHeaderProps } from '@progress/kendo-react-scheduler';
import ProgramContext from './ProgramContext';
import { Link } from 'react-router-dom';

import Slider from './Slider';
import { useDispatch, useSelector } from 'react-redux';
import { getPrograms } from '../features/dataSlice';
import { RootState } from '../app/store';
import { getSchedulerData } from '../Api/Programs';

export const CustomHeader = ( props : SchedulerHeaderProps) => {
    const [supports, setSupports] = React.useState(JSON.parse(localStorage.getItem('support')) || [])
    const dispatch = useDispatch();
    const programContext = React.useContext(ProgramContext);
    React.useEffect(()=>{
        getSchedulerData('support').then((result) => setSupports(result))
      },[])
   
  return (
    <SchedulerHeader>
        <div className="schedulerHeader">
            <div className="selectDiv">
                <select value={programContext.selectedSupport} onChange={programContext.selectSupport}>
                    {
                        supports.map((support)=>(
                            <option key={support._id} value={support.name}>{support.name}</option>
                            ))
                            
                        }
                </select>
            </div>
            {props.children}
            <Slider />
            <div className="logoutButton pull-right">
                <Link to="/">
                    <button onClick={programContext.logout} className="btn btn-danger">Se Déconnecter</button>
                </Link>
            </div>
        </div>
    </SchedulerHeader>

  )
}

