import React from 'react';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Slider, SliderLabel,SliderChangeEvent } from '@progress/kendo-react-inputs';
import { RootState } from '../app/store';
import { addSlide } from '../features/slideSlice';

const Zoom = (props) => {
    const slideValue = useSelector((state: RootState) => state.slide.slideValue)
    const dispatch = useDispatch()
    console.log(slideValue)
    const slideHanldeChange = (e) => {
        dispatch(addSlide(e.value));
        localStorage.setItem('slideValue', e.value);
    }
  return <Slider 
            buttons={true} 
            step={5} 
            defaultValue={slideValue} 
            min={5} 
            max={30}
            onChange={slideHanldeChange}
            >
      </Slider>;
};

export default (Zoom)