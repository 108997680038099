import axios from 'axios';
import React from 'react'
import Programdata from '../Components/Programdata';

const baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
export const getPrograms = async(support: string)=>{
    localStorage.setItem('selectedSupport', support);
    await axios.get(`${baseUrl}/program/support/${support}`)
    .then((response: any) => {
        if(response.data.error){
        }else{   
        const programs = response.data.data.slice(0, 50);
        // console.log(programs)
        localStorage.setItem('program', JSON.stringify(programs));
        }
    }).catch((err) => console.log(err.message));
}

export const filteredPrograms = ()=>{
    const localProgram = new Programdata(JSON.parse(localStorage.getItem('programs')));
   return JSON.parse(localStorage.getItem('programs')) ? localProgram.getProgram() : [];
}


export const categories = getSchedulerData('category')
export async function  getSchedulerData (service){
    // console.log(baseUrl)
    await axios.get(`${baseUrl}/${service}`)
          .then((response: any) => {
            if(response.data.error){
                console.log(response)
            }else{      
              localStorage.setItem(service, JSON.stringify(response.data.data));
            }
            
        }).catch((err) => console.log(err.message)); 
   return JSON.parse(localStorage.getItem(service));
  }